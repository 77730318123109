/**
 * Created by daniel on 21/9/24.
 */
import React from 'react';
import { connect } from 'react-redux'
import PublishLibraryView from "../../views/PublishLibraryView";
import SessionManager from "../../utils/SessionManager";
import {
    DatasetPublishingInfoResult,
    EnvidObjectSmallResult,
    SiteDataResult,
    VersionDetailsSmallResult
} from "../../fetchapi";
import { Dimensions, DispatchAction, EbaseAppState} from "../../redux/reducers";
import {Spinner} from "react-bootstrap";
import {PropsWithLocation} from "../../index";
import {Dispatch} from "redux";

interface PublishingStateProps {
    isLoading: boolean;
    dimensions: Dimensions | null;
    siteData: SiteDataResult | null;
    datasetId: string | null | undefined;
    changedObjs: EnvidObjectSmallResult[];
    historyRecords: VersionDetailsSmallResult[];
    historyVersionObjs: EnvidObjectSmallResult[];
    publishedVersion: VersionDetailsSmallResult | null | undefined;
}

interface PublishingDispatchProps {
    loadPublishInfo: () => void;
    unmountData: () => void;
    loadHistoryVersion: (versionId: string | null) => void;
}

export type PublishingHomeProps = PublishingStateProps & PublishingDispatchProps;

const mapStateToProps = (state: EbaseAppState, ownProps: PropsWithLocation) : PublishingStateProps =>
{
    // console.log(' all state', state);
    return {
        dimensions:state.session.dimensions,
        siteData: state.session.siteData,
        datasetId: state.session.siteData?.dataset?.datasetId,
        changedObjs: state.manage.changedObjects,
        historyRecords: state.manage.historyRecords,
        historyVersionObjs: state.manage.historyVersionObjects,
        isLoading: state.manage.isLoading,
        publishedVersion: state.manage.publishedVersion,
    };
};

const mapDispatchToProps = (dispatch: Dispatch<DispatchAction>, ownProps: PropsWithLocation):PublishingDispatchProps =>
{
    return {
        loadPublishInfo: () => loadPublishingInfo(dispatch, ownProps),
        unmountData: () => unmountPublishData(dispatch, ownProps),
        // loadChangedObjects: (versionId: string | null) => loadLibraryChanges(versionId, dispatch, ownProps),
        // loadHistory: () => loadHistoryRecords(dispatch, ownProps),
        loadHistoryVersion: (versionId: string | null) => loadLibraryVersionObjects(versionId, dispatch, ownProps),
    };
};


const loadLibraryVersionObjects = (versionId: string | null , dispatch: any, ownProps: PropsWithLocation) =>{
    let ebaseDs = SessionManager.shared().getEbaseDS();
    dispatch({type: 'LOAD_HISTORY_VERSION_START'});

    if (!!versionId) {
        //Promise<EnvidObjectSmallResult[]>
        ebaseDs.loadDatasetChangedObjects(versionId).then((result: EnvidObjectSmallResult[]) => {
            dispatch({type: 'LOAD_HISTORY_VERSION_DONE', data: result});
            console.log(' LOAD_HISTORY_VERSION_DONE result', result);

        }).catch((err: any) => {
            console.log('error loadLibraryChanges', err);
            dispatch({type: 'LOAD_HISTORY_VERSION_DONE', data: []});
        });
    }
}

const loadPublishingInfo = (dispatch: Dispatch<DispatchAction>, ownProps: PropsWithLocation) => {
    let ebaseDs = SessionManager.shared().getEbaseDS();
    dispatch({type: 'LOAD_PUBLISH_INFO_START'});

    ebaseDs.loadDatasetPublishingInfo().then((result: DatasetPublishingInfoResult) => {
        dispatch({type: 'LOAD_PUBLISH_INFO_DONE', data: result});
    }).catch( (err : any) => {
        console.log('error loadLibraryChanges', err);
        dispatch({type: 'LOAD_PUBLISH_INFO_DONE', data: {}});
    });
}
const unmountPublishData = (dispatch: any, ownProps: object) => {
    dispatch({type: 'LOAD_PUBLISH_INFO_DONE', data: {}});
}


class PublishViewHome extends React.Component<PublishingHomeProps> {


    constructor(props: PublishingHomeProps & PropsWithLocation)
    {
        super(props);
        this.props.loadPublishInfo();
    }

    componentWillUnmount() {
        this.props.unmountData();
    }

    render() {
        return (
                this.props.isLoading ? <Spinner /> : React.cloneElement(<PublishLibraryView/>, this.props)
        )
    }
};

const PublishingHomeContainer = connect(mapStateToProps, mapDispatchToProps)(PublishViewHome)

export default PublishingHomeContainer
