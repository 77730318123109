import React from 'react';
import ContainerHeader from '../components/common/ContainerHeader'
import PageWrapper from '../components/common/PageWrapper'
import EbasePanel from '../components/common/EbasePanel'
import {Table, FormControl, FormGroup, Form} from 'react-bootstrap'
import Spinner from "../components/common/Spinner"
import EButton from "../components/common/EButton"
import {withTranslation} from "react-i18next";

function TableHeader(props){
    const titles = props.titles;

    const ths = titles.map((title, i) =>
        <th key={title ? title : 'col-'+i}>{title}</th>
    );
    return (
        <thead><tr key='HeadingRow'>{ths}</tr></thead>
    );

}

class UsersView extends React.Component {

    constructor(props){

        super(props);

        this.onFilterChange = this.onFilterChange.bind(this);
        this.state = {filterString:""};

    }

    onFilterChange(event){

        //console.log("filter change");
        let value = event.target.value;

        this.setState({filterString:value});
    }

    componentWillMount()
    {
        this.props.load();
    }
    render() {
        const {t} = this.props;

        let body = <tbody></tbody>;

        // console.log("USersView;:");
        // console.log(this.props);

        if (this.props.isLoading || this.props.doLoad)
        {
            body = <Spinner/>
        }
        else {

            let users = this.props.theusers;

            if (users === undefined) {
                body = <tbody><tr><td>{t("No Data")}</td></tr></tbody>
            } else if (users.length === 0) {
                body = <tbody><tr><td>{t("No Data")}</td></tr></tbody>
            } else {

                let filter = this.state.filterString.trim();
                if(filter.trim().length > 0){
                    users = users.filter( u => u.username.toLowerCase().includes(filter.toLowerCase())
                        || u.firstname.toLowerCase().includes(filter.toLowerCase())
                        || u.lastname.toLowerCase().includes(filter.toLowerCase()))
                }

                users.sort((a,b) => {

                    let partsA = a.username.split("@");
                    let partsB = b.username.split("@");
                    if(partsA.length === 2 && partsB.length === 2){
                        return partsA[1].localeCompare(partsB[1]);
                    }
                    if(partsA.length === 1 && partsB.length === 2){
                        return a;
                    }
                    if(partsB.length === 1 && partsA.length === 2){
                        return b;
                    }

                    if(partsA.length === 1 && partsB.length === 1){
                        return partsA[0].localeCompare(partsB[0]);
                    }

                });

                const rows = users.map((user) =>

                    <tr key={user.userId}>
                        <td></td>
                        <td key={user.lastname}>{user.firstname} {user.lastname}</td>
                        <td key={user.username}>{user.username}</td>
                        <td><EButton type="edit" isTable={true}
                                     onClick={() => this.props.manageUser(user.userId)}>{t("Edit")}</EButton></td>
                    </tr>
                );
                body = <tbody key="body">{rows}</tbody>
            }

            body = (
                <Table className="table table-hover">
                    <TableHeader titles={["",t("Name"),t("Username"),""]}/>
                    {body}
                </Table>
            );
        }



        let addButton = <EButton type="add" onClick={() => this.props.manageUser("new")}>{t("New User")}</EButton>;


        return (
        <div>
            <ContainerHeader title={"Users"} crumbs={['/','/users']}  dimensions={this.props.dimensions} rightComponent={addButton}/>
            <PageWrapper dimensions={this.props.dimensions}>
                <EbasePanel>
                    <FormGroup style={{width:"25%"}}>
                        <Form.Label>{t("Username Filter")}</Form.Label>
                        <FormControl type="text" value={this.state.filterString} onChange={this.onFilterChange.bind(this)}/>
                    </FormGroup>
                    {body}
                </EbasePanel>
            </PageWrapper>
        </div>
        )
    }

}

export default withTranslation()(UsersView)
