/* tslint:disable */
/* eslint-disable */
/**
 * eBase REST api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ReportTemplateResult
 */
export interface ReportTemplateResult {
    /**
     * 
     * @type {string}
     * @memberof ReportTemplateResult
     */
    templateId?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportTemplateResult
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportTemplateResult
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportTemplateResult
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportTemplateResult
     */
    reportType?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ReportTemplateResult
     */
    requiredTasks?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof ReportTemplateResult
     */
    isDefault?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ReportTemplateResult
     */
    _default?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ReportTemplateResult
     */
    builtIn?: boolean;
}

/**
 * Check if a given object implements the ReportTemplateResult interface.
 */
export function instanceOfReportTemplateResult(value: object): value is ReportTemplateResult {
    return true;
}

export function ReportTemplateResultFromJSON(json: any): ReportTemplateResult {
    return ReportTemplateResultFromJSONTyped(json, false);
}

export function ReportTemplateResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportTemplateResult {
    if (json == null) {
        return json;
    }
    return {
        
        'templateId': json['templateId'] == null ? undefined : json['templateId'],
        'name': json['name'] == null ? undefined : json['name'],
        'description': json['description'] == null ? undefined : json['description'],
        'type': json['type'] == null ? undefined : json['type'],
        'reportType': json['reportType'] == null ? undefined : json['reportType'],
        'requiredTasks': json['requiredTasks'] == null ? undefined : json['requiredTasks'],
        'isDefault': json['isDefault'] == null ? undefined : json['isDefault'],
        '_default': json['default'] == null ? undefined : json['default'],
        'builtIn': json['builtIn'] == null ? undefined : json['builtIn'],
    };
}

export function ReportTemplateResultToJSON(value?: ReportTemplateResult | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'templateId': value['templateId'],
        'name': value['name'],
        'description': value['description'],
        'type': value['type'],
        'reportType': value['reportType'],
        'requiredTasks': value['requiredTasks'],
        'isDefault': value['isDefault'],
        'default': value['_default'],
        'builtIn': value['builtIn'],
    };
}

