import React, {Component} from 'react';
import {AuditEventPublic, EnvidObjectResult} from "../../fetchapi";
import ChangeHistoryView from "../../views/ChangeHistoryView";
import SessionManager from "../../utils/SessionManager";
import EbaseDS from "../../utils/EbaseDS";

interface ChangeHistoryContainerProps {
  currentObject: EnvidObjectResult
}

interface ChangeHistoryContainerState {
  objectHistory: AuditEventPublic[];
  isLoading: boolean;
}

class ChangeHistoryContainer extends Component<ChangeHistoryContainerProps, ChangeHistoryContainerState> {
  constructor(props: ChangeHistoryContainerProps) {
    super(props);
    this.state = {
      objectHistory: [],
      isLoading: false,
    };
  }

  componentDidMount() {
    // console.log('ChangeHistoryContainer:: didmount', this.props);
    const ebaseDs: EbaseDS = SessionManager.shared().getEbaseDS();
    this.setState({isLoading: true});

    ebaseDs.loadObjectChangeHistory(this.props.currentObject.object?.sharedId!).then((result: AuditEventPublic[]) => {
      // console.log(' loadObjectChangeHistory',result );
      this.setState({objectHistory: result});
      this.setState({isLoading: false});
    }).catch(reason => {
      console.log(reason);
    })
  }

  render() {
    return (
      <ChangeHistoryView history={this.state.objectHistory} isLoading={this.state.isLoading}/>
    );
  }
}

export default ChangeHistoryContainer;
