import React from 'react';
import EButton from "./EButton"
import { Table} from 'react-bootstrap'
import Select from 'react-select';
import values from "object.values"

class InputList extends React.Component {

    constructor()
    {
        super();
        this.state = {selected:null};
        this.add = this.add.bind(this);
    }

    add()
    {
        //console.log(this.state.selected);
        if (this.state.selected != null) {
            this.props.onAddInput(this.state.selected.value);
            this.setState({selected: null});
        }
    }

    remove(object_id)
    {
        this.props.onRemoveInput(object_id);
        this.setState({selected: null});
    }

    render() {

        let options = this.props.options;

        if (!Object.values) values.shim();

        return (
            <div>
            <div className="row">
                <div className="col-sm-10">
                    <Select
                        value={this.state.selected}
                        isDisabled={this.props.disabled}
                        options={options.filter((r) => !r.selected)}
                        placeholder="Select input to add..."
                        onChange={(v) => {this.setState({selected:v});}}

                    />
                </div>
                <div className="col-sm-1">
                    <EButton type="add" disabled={this.state.selected == null || this.props.disabled} onClick={this.add}>Add Input</EButton>
                </div>
            </div>

            <br/>
            <div className="row">
                <div className="col-sm-10">

                <Table>
                    <tbody>
                {
                    Object.values(options)
                    .sort((r1, r2) => r1.label.localeCompare(r2.label))
                    .filter((r) => r.selected)
                    .map((r) => {
                        return <tr key={r.sharedId}><td>{r.label}</td><td style={{textAlign:"right"}}><EButton type="remove" isTable={true} onClick={this.remove.bind(this, r.value)}>Remove</EButton></td></tr>
                    }, this)
                }
                    </tbody>
                </Table>

                </div>
            </div>
            </div>
        )
    }
}
export default InputList
