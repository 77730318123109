import React, {useState, useEffect} from 'react';
import {AuditEventPublic, AuditEventPublicEventTypeEnum} from "../fetchapi";
import EBTable from "../components/common/EBTable";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus, faRocketLaunch, faWarning} from "@fortawesome/pro-solid-svg-icons";
import {faUpload, faDownload, faPencil, faServer, faLink, faUnlink, faTrash} from "@fortawesome/pro-light-svg-icons";
import Spinner from "../components/common/Spinner";

interface ChangeHistoryViewProps {
    history: AuditEventPublic[];
    isLoading: boolean;
}

interface TableEntry {
    user: string;
    changeEvents: any[];
    versions: any[];
}

const ChangeHistoryView: React.FC<ChangeHistoryViewProps> = (props) => {
    // Define your state here
    const [state, setState] = useState({});
    const [history, setHistory] = useState<TableEntry[]>([])

    useEffect(() => {
        // Component did mount logic here
        calcTableArray();
        return () => {
            // Component will unmount logic here
        };
    }, []);

    useEffect(() => {
        // Component did update logic here
        calcTableArray();
    }, [state, props]);

//Preprocessing the data to group by contiguous user events
    const calcTableArray = () => {
        // console.log("(props.histor", props.history);
        if (!props.history) {return;}
        let temp: TableEntry[] = [];
        for (let ii = 0; ii < props.history.length; ii++) {
            let row = props.history[ii];
            if (row) {
                let currUser: string = row.userEvent?.firstName + " " + row.userEvent?.lastName;
                let currVersion: string = row.version ?? "";

                if ((temp.length == 0) || ((temp[temp.length-1].user ?? "") !== currUser)) {
                    temp.push({user: currUser, changeEvents: [{date: row.userEvent?.eventDate, description: row.description, index: ii, eventType: row.eventType}], versions: [{libVersion: currVersion, index: ii}]});
                } else {
                    temp[temp.length-1].changeEvents.push({date: row.userEvent?.eventDate, description: row.description, index: ii, eventType: row.eventType});
                    temp[temp.length-1].versions.push({libVersion: currVersion, index: ii});
                }
            }
        }

        setHistory(temp);
    }

    if (props.isLoading) {
        return <Spinner/>
    }
//Set each type of icon that can show in the table
    const getIcon = (eventType: AuditEventPublicEventTypeEnum) => {
        switch (eventType) {
            case "DELETE":      { return <FontAwesomeIcon size="lg" icon={faTrash}></FontAwesomeIcon>}
            case "CHECKOUT":    { return <FontAwesomeIcon size="lg" icon={faDownload}></FontAwesomeIcon>}
            case "CREATE":      { return <FontAwesomeIcon size="lg" icon={faPlus}></FontAwesomeIcon>}
            case "ERROR":       { return <FontAwesomeIcon size="lg" icon={faWarning}></FontAwesomeIcon>}
            case "CHECKIN":     { return <FontAwesomeIcon size="lg" icon={faUpload}></FontAwesomeIcon>}
            case "UPDATE":      { return <FontAwesomeIcon size="lg" icon={faPencil}></FontAwesomeIcon>}
            case "PUBLISH":     { return <FontAwesomeIcon size="lg" icon={faRocketLaunch}></FontAwesomeIcon>}
            case "SYSTEM":      { return <FontAwesomeIcon size="lg" icon={faServer}></FontAwesomeIcon>}
            case "LINK":      { return <FontAwesomeIcon size="lg" icon={faLink}></FontAwesomeIcon>}
            case "UNLINK":      { return <FontAwesomeIcon size="lg" icon={faUnlink}></FontAwesomeIcon>}
            default:              return <FontAwesomeIcon size="lg" icon={faWarning}></FontAwesomeIcon>;
        }
    }

    return (
        // <div>
        //     {JSON.stringify(history)}
        // </div>
        <div className="d-flex h-100 w-100 flex-column" style={{maxHeight: "100%"}}>
            <h3 className="m-b-sm m-t-lg">Object Change History</h3>
            <div className="overflow-y-auto">

                { (!!props.history && !!history) ?
                    <EBTable
                        columns={[
                            { header: 'User', field: 'user' },
                            {
                                header: 'Change Events',
                                field: 'changeEvents',
                                render: (re: any[]) => (
                                    <div className="w-100 d-flex flex-column">
                                        {re.map((changeEvent: any, index: number) => (
                                            <div className="w-100 d-flex gap-2 rounded-2 p-1" style={{backgroundColor: String(changeEvent.index % 2 !== 0 ? "#f5f9fc" : "")}}  key={"tr"+changeEvent.index} >
                                                <div className="font-monospace text-nowrap text" >{changeEvent.date.toLocaleString()}</div>
                                                <div className="">&nbsp;{getIcon(changeEvent.eventType)}</div>
                                                <div className="">{changeEvent.description}</div>
                                            </div>
                                        ))}
                                    </div>
                                )
                            },
                            {
                                header: <div className="eb-div-ctr w-100">{'Library Version'}</div>,
                                field: 'versions',
                                render: (re: any[]) => (
                                    <div className="eb-div-ctr d-flex flex-column">
                                        {re.map((version: any, index: number) => (
                                            <div className="w-100 d-flex gap-2 rounded-2 p-1" key={"lv"+version.index} >
                                                {(index == 0 || re[index-1].libVersion !== version.libVersion) ? <div className="w-100 eb-div-ctr">{version.libVersion}</div> : <div>&nbsp;</div>}
                                            </div>
                                        ))}
                                    </div>
                                )
                            },
                        ]}
                        data={history}
                        responsive classCustom=""></EBTable>
                    : <div className="m-t-md">Change history not found.</div>}
            </div>
        </div>
    )};

export default ChangeHistoryView;
