import React from 'react';
import jq from "./jq";
import i18n from '../i18n'
import {DefaultClientLogo} from "../img/images";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faRocketLaunch} from "@fortawesome/pro-solid-svg-icons";



export default {

    /**
     *
     * @param props
     * @return {SiteDataResult | undefined} SiteDataResult if it exists in props.
     */
    getSiteData(props)
    {
        return props.siteData;
    },

    /**
     *
     * @param props
     * @return {UserResult} UserResult in from siteData
     */
    getUser(props)
    {
        return this.getSiteData(props).user;
    },

    getClientLibraries(props){
        let sData = this.getSiteData(props);
        if (sData != null) {
            return sData.clientLibraries;
        }
        return props.clientLibraries
    },

    getCurrentDataset(props){
        let sData = this.getSiteData(props);
        if (sData != null) {
            return sData.dataset;
        }
        return props.dataset;
    },

    getClient(props)
    {
        let sData = this.getSiteData(props);
        if (sData != null) {
            return sData.client;
        }
        return props.client;
    },

    getAccess(props)
    {
        let sData = this.getSiteData(props);
        if (sData != null) {
            return sData.access;
        }
        return props.access;
    },

    getGlobalAccess(props){
        let sData = this.getSiteData(props);
        if (sData != null) {
            return sData.globalAccess;
        }
        return props.globalAccess;
    },

    getDevOverlayName(props)
    {
        if (this.getSiteData(props) != null && this.getSiteData(props).overlay != null)
        {
            return this.getSiteData(props).overlay.name;
        }

        return null;
    },

    getUserFullName(props)
    {
        if (this.getUser(props).lastname == null)
        {
            return this.getUser(props).firstname;
        }
        else {
            return this.getUser(props).firstname + " " + this.getUser(props).lastname;
        }

    },

    getUsername(props)
    {
        return this.getUser(props).username;
    },

    getClientName(props)
    {
        let client = this.getClient(props);
        if (client !== undefined && client !== null){
            return client.name;
        }
        return "";
        //return this.getClient(props).name;
    },

    getClientDesc(props)
    {
        return this.getClient(props).desc;
    },

    getUserPosition(props)
    {
        return this.getClientName(props);
    },

    getClientLogo(props){
        let c = this.getClient(props);
        if (c === null || c === undefined){
            return DefaultClientLogo;
        }

        if (c.logo === null || c.logo === undefined){
            return DefaultClientLogo;
        }

        return c.logo;
    },

    inArray(text, array)
    {
      return jq.inArray(text,array);
    },

    checkReadAccess(props, type)
    {
        if (type === "publish") {return true;}

        var access = "read-" + type;
        if (this.inArray(access, this.getAccess(props)) >= 0) return true;

        return false;
    },

    checkWriteAccess(props, type)
    {
        // console.log("checkWriteAccess "+type,props);
        // console.log("this.getAccess(props) ", this.getAccess(props));
        // console.log(props);
        // console.log("this.getAccess(props)  = "+this.getAccess(props));
        if (type === "publish") {return true;}

        var access = "write-" + type;
        if (this.inArray(access, this.getAccess(props)) >= 0) return true;

        return false;
    },

    checkGrantAccess(props, type)
    {
        var access = "grant-" + type;
        if (this.inArray(access, this.getAccess(props)) >= 0) return true;

        return false;
    },

    checkGlobalWriteAccess(props,type){
        var access = "write-" + type;
        if (this.inArray(access, this.getGlobalAccess(props)) >= 0) return true;

        return false;
    },

    lookupLinkName(link)
    {
        const t = i18n.t;
        if (t==null)
        {
            return "XXX";
        }

        if(link === "/"){
            return i18n.t("Home");
        }else if(link === "#"){
            return i18n.t("Home");
        }else if(link === "/clients") {
            return i18n.t("Clients");
        }else if(link === "/publishing"){
            return i18n.t("libraryPublishing", {defaultValue:'Publishing'});
        }else if(link.startsWith?.("/client/")){
            return i18n.t("Manage Client");
        }else if(link === "/projects"){
            return i18n.t("Project Hub");
        }else if(link === "/users"){
            return i18n.t("Users");
        }else if(link === "/select"){
            return i18n.t("Dashboard");
        }else if (link === "/enviddata") {
            return i18n.t("Manage Database");
        } else if (link === "/templates" || link === "/templates-readOnly") {
            return i18n.t("Manage Templates");
        }else if(link === "/enviddata-readOnly"){
            return i18n.t("View Data");
        }else if (link === "/report-defn"){
            return i18n.t("Envronmental Report");
        }else if (link === "/report-controlssummary"){
            return i18n.t("Controls Summary");
        }else if (link.startsWith("/projects/project")){
            return i18n.t("Project");
        }else if (link.startsWith("/report-defn/")){
            if (link.indexOf("task") > 0) {
                return i18n.t("Summary");
            }
            return i18n.t("Impact & Risk Assessment");
        }else if (link.startsWith("/compliance/")){
            if (link.indexOf("manage") > 0) {
                return i18n.t("Compliance Management");
            }
            return i18n.t("Compliance");
        }else if (link.startsWith("/stakeholder/")){
            if (link.indexOf("manage") > 0) {
                return i18n.t("Stakeholder Management");
            }
            return i18n.t("Stakeholders");
        }else if (link.startsWith("/report-controlssummary/")){
            if (link.indexOf("task") > 0) {
                return i18n.t("Summary");
            }
            return i18n.t("Controls Summary");
        }else if (link === "/help"){
            return i18n.t("Help");
        }else if (link === "/controlscatalogue"){
            return i18n.t("Controls Catalogue");
        }else if (link === "/knowledgebase"){
            return i18n.t("Knowledge Base");
        }else if(link === "/libraries"){
            return i18n.t("Libraries");
        }else if(link === "/stakeholdersummary"){
            return i18n.t("Stakeholder Summary");
        }else if (link === "/impactsummary"){
            return i18n.t("Impact Summary");
        }else if(link === "/regionsummary"){
            return i18n.t("Region Summary");
        }else if(link === "/receptorsummary"){
            return i18n.t("Receptor Summary");
        }else if(link === "/webmap"){
            return i18n.t("Maps");
        }

        return "";
    },

    lookupLinkIcon(link)
    {
        if(link === "/"){
            return <i className="fa fa-th-large"></i>
        }else if(link === "#"){
            return <i className="fa fa-th-large"></i>
        }else if(link === "/clients"){
            return <i className="fa fa-building"></i>
        }else if(link === "/publishing"){
            return <i><FontAwesomeIcon icon={faRocketLaunch} /></i>;
        }else if(link === "/projects"){
            return <i className="fa fa-folder"></i>
        }else if(link === "/users"){
            return <i className="fa fa-user-circle-o"></i>
        }else if(link === "/select"){
            return <i className="fa fa-th-large"></i>
        }else if (link === "/enviddata"){
            return <i className="fa fa-upload"></i>
        }else if (link === "/help"){
            return <i className="fa fa-question"></i>
        }else if (link === "/controlscatalogue"){
            return <i className="fa fa-umbrella"></i>
        }else if (link === "/knowledgebase"){
            return <i className="fa fa-book"></i>
        }else if (link === "/libraries"){
            return <i className="fa fa-university"></i>
        }else if (link === "/stakeholdersummary"){
            return <i className="fa fa-child"></i>
        }else if (link === "/impactsummary"){
            return <i className="fa fa-compress"></i>
        }else if (link === "/regionsummary"){
            return <i className="fa fa-map-marker"></i>
        }else if (link === "/receptorsummary"){
            return <i className="fa fa-tree"></i>
        }else if (link === "/webmap"){
            return <i className="fa fa-map-o"></i>
        } else if (link === "/templates" || link==="/templates-ReadOnly"){
            return <i className="fa fa-file-text"></i>
        }


        return <i className="fa fa-bars"></i>
    },

    getParams(props){
        return props.match.params;
    }



}
