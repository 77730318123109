import React from 'react';
import ContainerHeader from '../components/common/ContainerHeader'
import PageWrapper from '../components/common/PageWrapper'
import EbasePanel from '../components/common/EbasePanel'
import {Table} from 'react-bootstrap'
import Spinner from "../components/common/Spinner"
import EButton from "../components/common/EButton"
import EnvidObjectIcon from "../components/common/EnvidObjectIcon"
import sitedata from "../utils/sitedata";
import {Trans, withTranslation} from "react-i18next";


class ProjectsView extends React.Component {

    componentWillMount()
    {
        this.props.load();
    }

    projectClicked(project_id, type, e)
    {
        e.stopPropagation();
        this.props.projectClicked(project_id, type)
    }

    render() {

        const {t} = this.props;

        let body = null;
        let addButton = null;
        let readOnly = !sitedata.checkWriteAccess(this.props,"project");


        if (this.props.isLoading || this.props.doLoad)
        {
            body = <Spinner/>
        }
        else {
            const projects = this.props.list;

            projects.sort((a,b) => {return a.name.localeCompare(b.name)});

            body = <tbody></tbody>;

            if (projects == null || projects.length === 0) {
                body = <tbody>
                <tr>
                    <td>No Projects</td>
                </tr>
                </tbody>
            }
            else {

                const rows = projects.map((project) => {

                    let reportExists = project.details != null && project.details.reportExists === true;
                    let onEdit = this.projectClicked.bind(this, project.object_id, reportExists ? "report" : "edit");

                    return (
                        <tr key={project.object_id}
                            onClick={ onEdit}>
                            <td className="project-status"><span className="label label-primary">{t("Active")}</span></td>
                            <td className="project-title" style={{whiteSpace: "nowrap"}}><a
                                onClick={onEdit}>{project.name}</a>
                                <br/><EnvidObjectIcon type="Region"
                                                      showName={false}/> {(project.region == null) ? t("Unknown") : project.region.name}
                            </td>
                            <td className="project-text">{project.desc}</td>
                            <td className="project-actions" style={{whiteSpace: "nowrap"}}>
                                <EButton type={readOnly? "standart":"edit"} isTable={true}
                                         onClick={onEdit}>{readOnly ? t("View") : t("Edit")}</EButton>&nbsp;
                            </td>
                        </tr>)
                });

                body = <tbody key="body">{rows}</tbody>
            }

            if(!readOnly) {
                addButton = <div><EButton type="add" onClick={this.props.newProject}>{t("New Project")}</EButton></div>;
            }


            body = <Table className="table table-hover">{body}</Table>
        }

        return (
            <div>
                <ContainerHeader title={t("Project Hub")}
                                 crumbs={['/','/projects']}
                                 dimensions={this.props.dimensions}
                                 rightComponent={addButton}/>
                <PageWrapper dimensions={this.props.dimensions}>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="ibox float-e-margins">
                                <div className="ibox-content text-center p-md">
                                    <Trans i18nKey="project-hub-welcome">
                                        <h2><span className="text-navy">Welcome to your</span> eBase <span className="text-navy">Project Hub</span>.</h2>
                                        <p>Begin by creating your Project OR edit existing Projects below.</p>
                                    </Trans>
                                </div>
                            </div>
                        </div>
                    </div>

                    <EbasePanel>

                            {body}

                    </EbasePanel>
                </PageWrapper>
            </div>
        )
    }

}

export default withTranslation()(ProjectsView)
