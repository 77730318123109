/* tslint:disable */
/* eslint-disable */
/**
 * eBase REST api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LinkListResult
 */
export interface LinkListResult {
    /**
     * 
     * @type {string}
     * @memberof LinkListResult
     */
    sharedId?: string;
    /**
     * 
     * @type {string}
     * @memberof LinkListResult
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof LinkListResult
     */
    linkId?: string;
}

/**
 * Check if a given object implements the LinkListResult interface.
 */
export function instanceOfLinkListResult(value: object): value is LinkListResult {
    return true;
}

export function LinkListResultFromJSON(json: any): LinkListResult {
    return LinkListResultFromJSONTyped(json, false);
}

export function LinkListResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): LinkListResult {
    if (json == null) {
        return json;
    }
    return {
        
        'sharedId': json['sharedId'] == null ? undefined : json['sharedId'],
        'name': json['name'] == null ? undefined : json['name'],
        'linkId': json['linkId'] == null ? undefined : json['linkId'],
    };
}

export function LinkListResultToJSON(value?: LinkListResult | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'sharedId': value['sharedId'],
        'name': value['name'],
        'linkId': value['linkId'],
    };
}

