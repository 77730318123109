/* tslint:disable */
/* eslint-disable */
/**
 * eBase REST api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PublishDatasetRequest
 */
export interface PublishDatasetRequest {
    /**
     * 
     * @type {string}
     * @memberof PublishDatasetRequest
     */
    version?: string;
    /**
     * 
     * @type {string}
     * @memberof PublishDatasetRequest
     */
    notes?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PublishDatasetRequest
     */
    selectedObjects?: Array<string>;
}

/**
 * Check if a given object implements the PublishDatasetRequest interface.
 */
export function instanceOfPublishDatasetRequest(value: object): value is PublishDatasetRequest {
    return true;
}

export function PublishDatasetRequestFromJSON(json: any): PublishDatasetRequest {
    return PublishDatasetRequestFromJSONTyped(json, false);
}

export function PublishDatasetRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PublishDatasetRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'version': json['version'] == null ? undefined : json['version'],
        'notes': json['notes'] == null ? undefined : json['notes'],
        'selectedObjects': json['selectedObjects'] == null ? undefined : json['selectedObjects'],
    };
}

export function PublishDatasetRequestToJSON(value?: PublishDatasetRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'version': value['version'],
        'notes': value['notes'],
        'selectedObjects': value['selectedObjects'],
    };
}

