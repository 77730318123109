/**
 * Created by lewellyn on 28/9/18.
 */
import React from 'react';
import {
  FormControl,
  FormGroup,
  Form,
  DropdownButton,
  Dropdown,
  Table,
} from 'react-bootstrap';
import ContainerHeader from '../components/common/ContainerHeader';
import PageWrapper from '../components/common/PageWrapper';
import EbasePanel from '../components/common/EbasePanel';
import EButton from '../components/common/EButton';
import sitedata from '../utils/sitedata';
import Spinner from '../components/common/Spinner';
import {withTranslation} from 'react-i18next';
import SessionManager from '../utils/SessionManager';
import {DefaultClientLogo} from "../img/images";

class ClientManageView extends React.Component {
  constructor(props) {
    super(props);

    // console.log("ClientManageView:: constructor");
    // console.log(this.props);
    // console.log("----");

    let params = sitedata.getParams(this.props);
    let clientId = params.clientId;

    let client = {
      clientId: 'new',
      name: '',
      desc: '',
      logo: null,
      logoFile: null,
      defaultLogo: true,
    };
    let libs = [];
    let readOnlyLibs = [];
    let allLibs = [];
    let checkSet = new Set();

    let clientLibraries = sitedata.getClientLibraries(props);

    if (clientLibraries) {
      // console.log('clientLibraries ',clientLibraries);

      for (let i = 0; i < clientLibraries.length; i++) {
        let clib = clientLibraries[i];

        for (let j = 0; j < clib.libraries.length; j++) {
          let lib = clib.libraries[j];

          if (!checkSet.has(lib.datasetId)) {
            allLibs.push(lib);
          }

          checkSet.add(lib.datasetId);

          if (clib.client.clientId === clientId) {
            client = clib.client;
            if (lib.clientDatasetAccessType === 'WRITE') {
              libs.push(lib);
            } else {
              readOnlyLibs.push(lib);
            }
          }
        }
      }
    }

    libs.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });
    allLibs.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });
    readOnlyLibs.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });

    if (client.logo === undefined) {
      client.logo = null;
      client.defaultLogo = true;
    }

    if (client.logo !== null) {
      client.defaultLogo = false;
    }

    // console.log("constructor client");
    // console.log(client);

    this.state = {
      client: client,
      allLibraries: allLibs,
      libraries: libs,
      readonlyLibraries: readOnlyLibs,
      editingLibrary: null,
      addLib: null,
      existingSelected: null,
      addLibCount: 0,
      //libraryType:null
    };

    this.validateField = this.validateField.bind(this);
    this.editDataset = this.editDataset.bind(this);
    this.addDataset = this.addDataset.bind(this);
    this.checkCanUpdate = this.checkCanUpdate.bind(this);
    this.onSelectExisting = this.onSelectExisting.bind(this);
    //this.onLibraryType = this.onLibraryType.bind(this);
    this.onRemoveExistingClick = this.onRemoveExistingClick.bind(this);
    this.fileSelectChange = this.fileSelectChange.bind(this);
    this.fileReadDone = this.fileReadDone.bind(this);
    this.resetLogo = this.resetLogo.bind(this);
  }

  componentWillMount() {
    // console.log("ClientManageView:: will mount");
    // console.log(this.props);
    // console.log("----");

    let params = sitedata.getParams(this.props);
    let clientId = params.clientId;
    if (clientId !== undefined && clientId !== null) {
      if (clientId.toLowerCase() !== 'new') {
      }
    }
  }

  validateField(field, value) {
    if (value === null || value.trim().length > 0) {
      return null;
    } else {
      return 'error';
    }
  }

  onFieldChange(field, event) {
    // console.log("fieldChanged");
    // console.log(field);
    // console.log(event);
    let value = event.target.value;

    let client = this.state.client;
    if (field === 'name') {
      client.name = value;
      this.setState({client: client});
    }
    if (field === 'desc') {
      client.desc = value;
      this.setState({client: client});
    }
    if (field === 'editname') {
      let editLib = this.state.editingLibrary;
      editLib.name = value;
      this.setState({editingLibrary: editLib});
    }
    if (field === 'editdesc') {
      let editLib = this.state.editingLibrary;
      editLib.desc = value;
      this.setState({editingLibrary: editLib});
    }
    if (field === 'editLanguageCode') {
      let editLib = this.state.editingLibrary;
      editLib.languageCode = value;
      this.setState({editingLibrary: editLib});
    }
    if (field === 'add') {
      let add = this.state.addLib;
      let addLibCount = this.state.addLibCount;
      if (add === null) {
        addLibCount++;
        add = {datasetId: 'new-'+addLibCount, name: value, languageCode: 'en'};
      } else {
        add.name = value;
      }
      this.setState({addLib: add, addLibCount: addLibCount});
    }
    if (field === 'libname') {
      let add = this.state.addLib;
      let addLibCount = this.state.addLibCount;
      if (add === null) {
        addLibCount++;
        add = {datasetId: 'new-'+addLibCount, name: value, desc: '', languageCode: 'en'};
      } else {
        add.name = value;
      }
      this.setState({addLib: add, addLibCount: addLibCount});
    }
    if (field === 'libdesc') {
      let add = this.state.addLib;
      let addLibCount = this.state.addLibCount;
      if (add === null) {
        addLibCount++;
        add = {datasetId: 'new-'+addLibCount, name: '', desc: value, languageCode: 'en'};
      } else {
        add.desc = value;
      }
      this.setState({addLib: add, addLibCount: addLibCount});
    }
    if (field === 'addLanguageCode') {
      let add = this.state.addLib;
      let addLibCount = this.state.addLibCount;
      if (add === null) {
        addLibCount++;
        add = {datasetId: 'new-'+addLibCount, name: '', desc: '', languageCode: value};
      } else {
        add.languageCode = value;
      }
      this.setState({addLib: add, addLibCount: addLibCount});
    }
  }

  checkCanUpdate() {
    let client = this.state.client;
    if (client === null || client === undefined) {
      return false;
    }

    if (client.name === null || client.name === undefined || client.name.length === 0) {
      return false;
    }

    if (client.desc === null || client.desc === undefined || client.desc.length === 0) {
      return false;
    }

    if (this.state.addLib) {
      return false;
    }

    if (!this.state.libraries || this.state.libraries.length === 0) {
      if (!this.state.readonlyLibraries || this.state.readonlyLibraries.length === 0) {
        return false;
      }
    }

    return true;
  }

  onUpdateClick() {
    // console.log("Update click");
    // console.log(this.state);

    let libraries = this.state.libraries;
    let existing = this.state.readonlyLibraries.map(l => {
      return l.datasetId;
    });

    this.props.updateClient(this.state.client, libraries, existing);
  }

  editDataset(dataset) {
    this.setState({editingLibrary: dataset});
  }

  addDataset() {
    let add = this.state.addLib;
    if (add !== null) {
      let libs = this.state.libraries;
      libs.push(add);

      this.setState({libraries: libs, addLib: null});
    }
  }

  editTemplates(client, dataSetId) {
    SessionManager.shared().goto('/client/' + client.clientId + '/templates/' + dataSetId);
  }

  onSelectExisting(datasetId) {
    this.setState({existingSelected: datasetId});

    let readOnlyLibs = this.state.readonlyLibraries;
    let libs = this.state.allLibraries;

    // console.log(readOnlyLibs);

    for (let i = 0; i < libs.length; i++) {
      let lib = libs[i];

      if (lib.datasetId === datasetId) {
        readOnlyLibs.push(lib);
        break;
      }
    }

    this.setState({readonlyLibraries: readOnlyLibs});
  }

  onRemoveExistingClick(datasetId) {
    // console.log("remove "+datasetId);
    let readOnlyLibs = this.state.readonlyLibraries.filter(l => l.datasetId !== datasetId);
    this.setState({readonlyLibraries: readOnlyLibs});
  }

  fileSelectChange(event) {
    console.log('file select change');
    console.log(event.target.files);
    console.log('');

    if (event.target.files.length > 0) {
      let file = event.target.files[0];

      let isImage = file.type === 'image/png' || file.type === 'image/jpg' || file.type === 'image/jpeg';

      if (isImage === true) {
        let reader = new FileReader();

        reader.onload = () => {
          this.fileReadDone(file, reader.result);
        };

        reader.readAsDataURL(file);
      } else {
        //this.showLoadError("Bad File", "Only PNG or JPEG files are accepted");
      }
    }
  }

  fileReadDone(file, data) {
    // console.log("file read done");
    // console.log(data);

    let c = this.state.client;
    c.logo = data;
    c.logoFile = file;
    c.defaultLogo = false;

    this.setState({client: c});
  }

  resetLogo() {
    let c = this.state.client;
    c.logo = null;
    c.logoFile = null;
    c.defaultLogo = true;

    this.setState({client: c});
  }

  getLanguageName(languageCode, languages) {
    let lang = languages[languageCode];
    if (lang == null) {
      return '';
    }
    return lang.name;
  }

  render() {
    const {t} = this.props;

    let content = '';
    let subtitle = '';
    let actionButtonText = 'Update Client';
    let client = this.state.client;
    let isNewClient = false;

    // console.log("client:");
    // console.log(client);
    // console.log("ClientManager render");
    // console.log(this.props);

    if (client.clientId === 'new') {
      isNewClient = true;
      actionButtonText = 'Create Client';
    }

    let libraryHeader = (
      <>
        <colgroup>
          <col style={{width: '25%'}} />
          <col style={{width: '25%'}} />
          <col style={{width: '25%'}} />
          <col style={{width: '20%'}} />
        </colgroup>
        <thead>
          <tr>
            <th>{t('Name')}</th>
            <th>{t('Description')}</th>
            <th>{t('Language')}</th>
            <th>&nbsp;</th>
          </tr>
        </thead>
      </>
    );

    let name = client.name;
    let description = client.desc;
    // let newLibName = "";
    // let newLibDesc = "";
    // if(newLib !== null){
    //     newLibName = newLib.name;
    //     newLibDesc = newLib.desc;
    // }

    let languageList = this.props.siteData.languages;
    let languages = {};
    for (let i in languageList) {
      languages[languageList[i].languageCode] = languageList[i];
    }

    if (Object.keys(languages).length === 0) {
      languages = {
        en: {
          languageCode: 'en',
          name: 'English',
          description: 'English',
        },
      };
    }

    let fieldWidth = '45%';

    let updateDone = this.props.updateDone;

    if (this.props.isLoading) {
      content = <Spinner />;
    } else if (updateDone === true) {
      let info = t('Client Updated Successfully');
      if (isNewClient) {
        info = t('Client Created Successfully');
      }

      content = (
        <div>
          <div>
            <h3>{info}</h3>
          </div>
          <div
            style={{
              width: 300,
              height: '100%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}>
            <EButton onClick={this.props.cancelClick} type="standard">
              {t('Done')}
            </EButton>
          </div>
        </div>
      );
    } else {
      ///ebase/envid-admin/read-enviddata-image/5c19d5f324ffaa3678204701
      //<img style={{width:48,height:48,borderRadius:'50%',border:'1px solid black'}} src={client.logo === null ? "img/greenlight-logo.png" : client.logo}/>

      // {
        /*<img src="/ebase/envid-admin/read-enviddata-image/5a6a7292216f7377d6c4b2cb">*/
      // }

      let clientInput = (
        <FormGroup style={{width: fieldWidth}}>
          <h3>{t('Client Details')}</h3>
          <FormGroup key="name" style={{}} validationState={this.validateField('name', name)}>
            <Form.Label>{t('Name')}</Form.Label>
            <div>
              <FormControl
                type={'text'}
                style={{width: '95%', fontSize: '13px'}}
                value={name}
                onChange={this.onFieldChange.bind(this, 'name')}
              />
            </div>
          </FormGroup>
          <FormGroup key="desc" validationState={this.validateField('desc', description)}>
            <Form.Label>{t('Description')}</Form.Label>

            <FormControl
              type={'text'}
              style={{width: '95%', fontSize: '13px'}}
              value={description}
              onChange={this.onFieldChange.bind(this, 'desc')}
            />
          </FormGroup>

          <Form.Label>{t('Company Logo')}</Form.Label>

          <div style={{display: 'flex', flexDirection: 'row', marginLeft: 10}}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}>
              <div style={{width: 48, height: 48}}>
                <img
                    alt="Client Logo"
                  style={{
                    width: 48,
                    height: 48,
                    borderRadius: '50%',
                    border: '1px solid black',
                  }}
                  src={client.logo === null ? DefaultClientLogo : client.logo}
                />
              </div>
            </div>
            <div
              style={{
                marginLeft: 30,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}>
              <EButton onClick={() => this.refs.uploadFileRef.click()} isTable={true} type="add">
                {t('Select')}
              </EButton>
              <EButton onClick={() => this.resetLogo()} isTable={true} type="default">
                {t('Use Default')}
              </EButton>

              <input type="file" ref="uploadFileRef" style={{display: 'none'}} onChange={this.fileSelectChange} />
            </div>
          </div>
        </FormGroup>
      );

      let buttons = (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            paddingTop: 10,
            borderTop: '1px solid #eaeaea',
          }}>
          <EButton onClick={this.props.cancelClick} type="cancel">
            {t('Cancel')}
          </EButton>
          <div style={{marginLeft: 25}}>
            <EButton
              onClick={this.onUpdateClick.bind(this)}
              type={isNewClient ? 'add' : 'done'}
              disabled={!this.checkCanUpdate()}>
              {t(actionButtonText)}
            </EButton>
          </div>
        </div>
      );

      let editLib = this.state.editingLibrary;

      let currentLibraries = this.state.libraries;
      let currentRows = [];

      if (currentLibraries === null || currentLibraries === undefined) {
        currentLibraries = [];
      }

      for (let i = 0; i < currentLibraries.length; i++) {
        let lib = currentLibraries[i];

        let selectedLanguageName = this.getLanguageName(lib.languageCode, languages);
        if (selectedLanguageName === '') {
          selectedLanguageName = languages['en'].name;
        }

        let languageItems = [];
        for (let langCode in languages) {
          languageItems.push(
            <Dropdown.Item
              // onSelect={() => this.onFieldChange('editLanguageCode', {target: {value: langCode}})}
              active={langCode === lib.languageCode}
              eventKey={langCode}>
              {languages[langCode].name}
            </Dropdown.Item>,
          );
        }

        let editBtn = (
          <EButton onClick={() => this.editDataset(lib)} type="edit" isTable={true}>
            {t('Edit')}
          </EButton>
        );

        let editTempBtn = isNewClient ? (
          <div>&nbsp;</div>
        ) : (
          <EButton onClick={() => this.editTemplates(client, lib.datasetId)} type="report" isTable={true}>
            {t('Templates')}
          </EButton>
        );

        if (editLib !== null) {
          if (editLib.datasetId === lib.datasetId) {
            editBtn = (
              <EButton onClick={() => this.editDataset(null)} type="standard" isTable={true}>
                {t('Done')}
              </EButton>
            );
            lib = editLib;

            currentRows.push(
              <tr key={'lib-edit'}>
                <td>
                  <FormControl
                    type={'text'}
                    style={{width: '100%', fontSize: '13px'}}
                    value={lib.name}
                    onChange={this.onFieldChange.bind(this, 'editname')}
                  />
                </td>
                <td>
                  <FormControl
                    type={'text'}
                    style={{width: '100%', fontSize: '13px'}}
                    value={lib.desc}
                    onChange={this.onFieldChange.bind(this, 'editdesc')}
                  />
                </td>
                <td>
                  <DropdownButton title={selectedLanguageName} onSelect={(eventKey) => this.onFieldChange('editLanguageCode', {target: {value: eventKey}})} >{languageItems}</DropdownButton>
                </td>
                <td>
                  {editTempBtn}&nbsp;{editBtn}
                </td>
              </tr>,
            );
          } else {
            currentRows.push(
              <tr key={'lib-'+currentRows.length}>
                <td>{lib.name}</td>
                <td>{lib.desc}</td>
                <td>{selectedLanguageName}</td>
                <td>
                  {editTempBtn}&nbsp;{editBtn}
                </td>
              </tr>,
            );
          }
        } else {
          currentRows.push(
            <tr key={'lib-'+currentRows.length}>
              <td>{lib.name}</td>
              <td>{lib.desc}</td>
              <td>{selectedLanguageName}</td>
              <td>
                {editTempBtn}&nbsp;{editBtn}
              </td>
            </tr>,
          );
        }
      }

      let addBtn = (
        <EButton onClick={() => this.addDataset()} type="add" isTable={true}>
          {t('Add')}
        </EButton>
      );

      let addName = '';
      let addDesc = '';
      let addLanguageCode = 'en';

      if (this.state.addLib !== null) {
        addName = this.state.addLib.name;
        addDesc = this.state.addLib.desc;
        addLanguageCode = this.state.addLib.languageCode;
      }

      let addLanguageTitle = this.getLanguageName(addLanguageCode, languages);

      let defaultLanguageItems = [];
      Object.keys(languages).forEach(langCode => {
        defaultLanguageItems.push(
          <Dropdown.Item
            key={langCode}
            // onSelect={() => this.onFieldChange('addLanguageCode', {target: {value: langCode}})}
            active={langCode === addLanguageCode}
            eventKey={langCode}>
            {languages[langCode].name}

          </Dropdown.Item>,
        );
      });

      currentRows.push(
        <tr key="lib-new">
          <td>
            <FormControl
              type={'text'}
              style={{width: '100%', fontSize: '13px'}}
              value={addName}
              onChange={this.onFieldChange.bind(this, 'libname')}
            />
          </td>
          <td>
            <FormControl
              type={'text'}
              style={{width: '100%', fontSize: '13px'}}
              value={addDesc}
              onChange={this.onFieldChange.bind(this, 'libdesc')}
            />
          </td>
          <td>
            <DropdownButton id='add-languages' title={addLanguageTitle} onSelect={(eventKey) => this.onFieldChange('addLanguageCode', {target: {value: eventKey}})}>{defaultLanguageItems}</DropdownButton>
          </td>

          <td>{addBtn}</td>
        </tr>
      );

      let body = <tbody>{currentRows}</tbody>;

      let libraryTable = (
        <div style={{width: '100%'}}>
          <Table className="table table-hover no-margins">
            {libraryHeader}
            {body}
          </Table>
        </div>
      );

      let readOnlyRows = [];
      let checkSet = new Set();

      for (let i = 0; i < this.state.readonlyLibraries.length; i++) {
        let lib = this.state.readonlyLibraries[i];
        checkSet.add(lib.datasetId);

        let editTempBtn = isNewClient ? (
          <div>&nbsp;</div>
        ) : (
          <EButton onClick={() => this.editTemplates(client, lib.datasetId)} type="report" isTable={true}>
            {t('Templates')}
          </EButton>
        );
        let removeBtn = (
          <EButton onClick={() => this.onRemoveExistingClick(lib.datasetId)} isTable={true} type="remove">
            {t('Remove')}
          </EButton>
        );

        readOnlyRows.push(
          <tr>
            <td>{lib.name}</td>
            <td>{lib.desc}</td>
            <td>{this.getLanguageName(lib.languageCode, languages)}</td>
            <td>
              {editTempBtn}&nbsp;{removeBtn}
            </td>
          </tr>,
        );
      }

      let ll = this.state.libraries;

      ll.forEach(l => {checkSet.add(l.datasetId)});

      let options = [];
      let selected = t('Select Existing Library');
      for (let i = 0; i < this.state.allLibraries.length; i++) {
        let lib = this.state.allLibraries[i];
        if (!checkSet.has(lib.datasetId)) {
          options.push(
            <Dropdown.Item
              key={lib.datasetId}
              // onSelect={() => this.onSelectExisting(lib.datasetId)}
              active={lib.datasetId === this.state.existingSelected}
              eventKey={lib.datasetId}>
              {lib.name}
            </Dropdown.Item>,
          );
        }

        if (lib.datasetId === this.state.existingSelected) {
          selected = lib.name;
        }
      }

      let dropDown = <DropdownButton id='existing-dataset' title={selected} onSelect={(eventKey) => this.onSelectExisting(eventKey)}>{options}</DropdownButton>;

      let readOnly = [];

      if (readOnlyRows.length > 0) {
        readOnly = (
          <Table className="table table-hover no-margins">
            {libraryHeader}
            <tbody>{readOnlyRows}</tbody>
          </Table>
        );
      }

      //console.log("logo "+client.logo);
      content = (
        <div>
          {clientInput}
          <div
            style={{
              width: '100%',
              border: '1px solid #eaeaea',
              marginBottom: 20,
            }}></div>
          <FormGroup>
            <Form.Label>{t('Read Only Libraries')}:</Form.Label>
            <div>{dropDown}</div>
            {readOnly}
          </FormGroup>

          <FormGroup>
            <Form.Label>
              {name} {t('Libraries')}:
            </Form.Label>
            {libraryTable}
          </FormGroup>

          {buttons}
        </div>
      );
    }

    //}

    return (
      <div>
        <ContainerHeader
          title={t('Manage')}
          crumbs={['/', t('Manage Client')]}
          dimensions={this.props.dimensions}
          subtitle={subtitle}
        />
        <PageWrapper dimensions={this.props.dimensions}>
          <EbasePanel>{content}</EbasePanel>
        </PageWrapper>
      </div>
    );
  }
}

export default withTranslation()(ClientManageView);
