/**
 * Created by craig on 08/08/17.
 */
import React from 'react'
import ebase from '../../utils/ajax'
import DataSummary from '../../views/DataSummary'

class DataSummaryContainer extends React.Component{


    constructor(){
        super();

        this.state = {
            summary : null,
            error:null,
            loading:null
        };

        this.getObjectId = this.getObjectId.bind(this);

    }

    getObjectId()
    {
        return this.props.currentObject.object.objectId;
    }

    loadSummary()
    {
        var request = {
            object_id:this.getObjectId(),
        }

        ebase.ajax({
            url:"/ebase/envid-admin/read-enviddata-summary",
            data:request,
            success: this.summarySuccess.bind(this, request),
            error:   () => this.setState({error:"Error talking to server"})
        });
    }

    summarySuccess(request, result)
    {
        var summary = {};

        summary.object_id = request.object_id;

        if (!result.success)
        {
            summary.error = result.error;
        }
        else
        {
            summary = result;
        }

        this.setState({summary : summary.data, loading:null});
    }



    render()
    {
        let bNeedsReload = false;

        if (this.state.summary == null)
        {
            // show the spinner, and request they be loaded
            if (this.state.loading == null || this.state.loading !== this.getObjectId()) {
                bNeedsReload = true;
            }
        }

        if (bNeedsReload)
        {
            this.loadSummary();
            this.state.loading = this.getObjectId();
        }

        return (
            <DataSummary loading={this.state.loading!=null}
                         currentObject={this.props.currentObject}
                         summary={this.state.summary}
                         siteData={this.props.siteData}
                         height={this.props.height}
                         callbacks={this.props.callbacks}
            />
        );


    }


}

export default DataSummaryContainer


