import React from 'react';
import {ListGroup, ListGroupItem, Table} from 'react-bootstrap'
import Spinner from '../components/common/Spinner'
import EButton from '../components/common/EButton'
import envid from '../utils/envid'
import EnvidObjectIcon from "../components/common/EnvidObjectIcon"
import DataDetailsContainer from '../components/containers/DataDetailsContainer'
import {Trans, withTranslation} from "react-i18next";
import {CompareTrigger} from "../components/common/CompareTrigger";

/**
 * Renders the data link tab.
 *
 * props
 */
class DataLink extends React.Component {

    constructor(props)
    {
        super(props);

        this.itemSelected = this.itemSelected.bind(this);
        this.addLink = this.addLink.bind(this);
        this.removeLink = this.removeLink.bind(this);
        this.state = {showManage:true, selectedLink:null};

        this.updateLinkObject = this.updateLinkObject.bind(this);
        // console.log('dataLink:: ',this.props);
    }

    updateLinkObject(object)
    {
        console.log(' DataLink:: updateLinkObject', object)
        this.props.updateLinkObject(object);
    }

    itemSelected(item)
    {
        this.setState({showManage:false});
        this.props.loadLinkObject(item.linkId);
    }

    removeLink(link_id)
    {
        this.props.removeLink(link_id);
    }

    addLink(sharedId)
    {
        var request = {link_type : this.props.template.linkObject,
                        left_type   : this.props.currentObject.object.type,
                        left_id     : this.props.currentObject.object.sharedId,
                        right_type  : this.props.template.showObject,
                        right_id    : sharedId};

        this.props.addLink(request);
    }

    addExistingObject()
    {
        this.setState({showManage:true});
    }

    addNewObject()
    {
        let link = {
            link_type   : this.props.template.linkObject,
            object_type : this.props.currentObject.object.type,
            object_id   : this.props.currentObject.object.objectId
        };
        this.props.addObject(this.props.template.showObject, link);

    }

    render() {
        const {t} = this.props;
        // console.log(' DataLink:: props', this.props);

        if (this.props.loading)
        {
            return <Spinner/>
        }

        let showObjectType = this.props.template.showObject;
        let thisObjectType = this.props.currentObject.object.type;
        let thisObjectName = thisObjectType;
        let showObjectName = showObjectType;

        // #TODO: check the current object checkedoutUserId against the session user id. should be in props?
        let checkedOut = Boolean(this.props.currentObject.object.checkoutUserId);

        let inputDisabled = !checkedOut || !!this.props.currentObject.object.deleteState;

        let object = this.props.currentObject;
        let linkInfo = this.props.linkInfo;

        if (linkInfo == null)
        {
            if(thisObjectType === "Control"){
                return <div className="label-error">{t("No Impact Selected")}</div>
            }else {
                return <div>{t("ERROR linkInfo should not be null")}</div>;
            }
        }

        if (linkInfo.error != null)
        {
            return <div className="label-error">{linkInfo.error}</div>
        }

        let list = linkInfo.list;
        let linkObject = linkInfo.currentObject;
        let linkObjectId = (linkInfo.currentObject == null ) ? null : linkInfo.currentObject.object.sharedId;

        var selectedName = null;
        var items = list
            .sort((r1, r2) => (r1.name > r2.name)?1:-1)
            .map(function (item) {

            if (item.linkId == null) return null;

             if (linkObjectId === item.linkId)
             {
                 selectedName = item.name;
             }

             let isActive = linkObjectId === item.linkId && !this.state.showManage;
            return (
                <ListGroupItem active={isActive}
                               onClick={this.itemSelected.bind(this, item)}
                                key={item.objectId}>
                    <EnvidObjectIcon type={showObjectType} showName={false} ignoreColour={isActive}/>
                    <span style={{marginLeft: "10px"}}>
                        {item.name}
                    </span>
                </ListGroupItem>

            )
        }, this).filter((item) => (!!item));

        var fields = [];
        var style = {marginBottom:"20px"};
        if (linkInfo.loading)
        {
            fields.push(<Spinner/>);
        }
        else if (this.state.showManage)
        {
            var tbody;

            if(list.length === 0){
                tbody = <tr><td>{t("You'll have to create")} {envid.getObjectSingleName(showObjectType)}</td></tr>
            }else{

                const rows = list
                                .sort((r1, r2) => ((((r1.linkId==null)?"1":"0")+r1.name) > (((r2.linkId==null)?"1":"0")+r2.name))?1:-1)
                                .map((item) =>

                    <tr key={item.objectId}>
                        <td className="project-status"><span className={"label label-" + ((item.linkId!=null)?"primary":"warning")}>{(item.linkId!=null?t("Linked"):t("Unlinked"))}</span></td>
                        <td className="project-title"><span style={{fontSize:"normal", fontWeight:"normal"}}>{item.name}</span></td>
                        <td className="project-actions">
                            {
                                (item.linkId==null)?
                                    <EButton disabled={inputDisabled} type="link" isTable={true} onClick={this.addLink.bind(this, item.sharedId)}>{t("Link")}</EButton>
                                        :
                                    <EButton disabled={inputDisabled} type="unlink" isTable={true} onClick={this.removeLink.bind(this, item.linkId)}>{t("Unlink")}</EButton>
                            }
                        </td>
                    </tr>
                , this);

                tbody = <tbody>{rows}</tbody>;
            }



            fields.push(

                <div key="manage">
                    <h3>{t("Manage Linked")} {envid.getObjectPluralName(showObjectType)}</h3>

                    <div className="project-list" style={style}>
                        <Table className="table table-hover">
                            {tbody}
                        </Table>
                    </div>

                </div>
            );
        }
        else if (linkObject == null)
        {
            const showObjectTypePlural = envid.getObjectPluralName(showObjectType)
            fields.push(
                <div key="link"><div>
                    <Trans i18nKey="relationships-description">
                        <h3>Relationships of {{thisObjectName}} to {{showObjectName}}</h3>

                        You can define your relationships to {{showObjectTypePlural}} here. Perform one of the following tasks.
                    </Trans>
                        <ul className="feed-activity-list">
                            <li className="feed-element"></li>
                            <li className="feed-element">
                                <Trans i18nKey="select-related">Select one of the existing related {{showObjectTypePlural}} and enter the relationship details.</Trans>
                            </li>
                            <li className="feed-element">
                                <EButton onClick={this.addNewObject.bind(this)} type="add">
                                    {t("Manage Linked")} {showObjectTypePlural} ??</EButton>
                                <br/>{t("You can add a remove links to")} {showObjectTypePlural}</li>
                        </ul>
                    </div>

                </div>
            );
        }
        else
        {
            fields.push(
                    <div className="d-flex justify-content-between m-b-sm">
                        <h3 key="name">
                            {object.object.details.name} {'>'} {selectedName}
                        </h3>
                        <div>
                            {(this.props.template.allowCompare) ?
                                <CompareTrigger targetObj={linkObject.object} customName={object.object.details.name + '>' + selectedName}></CompareTrigger> : <></>}
                        </div>
                    </div>
            );
            fields.push(
                        <DataDetailsContainer key="details"
                                              siteData={this.props.siteData}
                                              updateObject={this.updateLinkObject}
                                              updateSavedStatus={this.props.updateSavedStatus}
                                              currentObject={linkObject}
                                              currentCheckedOutObject={this.props.currentCheckedOutObject}
                                              fields={this.props.template.fields}/>);
        }

        //var height = this.props.dimensions.window.height - this.props.dimensions.extras.height - ;
        var height = this.props.height - 20;
        return (
            <div>

                <div style={{float:"left", width:"300px", height:height, overflow:"scroll"}}>
                    <ListGroup className={items.length != 0 ? "linked-hover" : ""}>
                        <ListGroupItem onClick={this.addExistingObject.bind(this)} key="manage" active={this.state.showManage}>
                            {items.length != 0 ? t("Manage Linked") : t("No Linked")} {envid.getObjectPluralName(showObjectType)}
                        </ListGroupItem>
                        {items}

                    </ListGroup>
                </div>

                <div style={{marginLeft:"370px", marginRight:"50px", height:height, overflow:"scroll", marginBottom:"0px"}}>
                        {fields}
                </div>

            </div>
        )
    }

}

export default withTranslation()(DataLink)
