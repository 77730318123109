import React from 'react';
import ReactTimeout from 'react-timeout';
import Spinner from "../components/common/Spinner";
import DataDetails from './DataDetails';
import details from '../utils/details'
import DataDetailsReadOnly from "./DataDetailsReadOnly";
import {withTranslation} from "react-i18next";

class ProjectDetails extends React.Component {


    constructor(props){

        super(props);

        // console.log("ProjectDetails ",this.props);
        // console.log(props);
        // console.log("---------- ProjectDetails ----------");

        this.saverTimeout = this.saverTimeout.bind(this);
        this.saveFields = this.saveFields.bind(this);
        this.updateField = this.updateField.bind(this);

        this.last_update_time = Date.now();
    }

    componentWillMount()
    {
        // console.log("ProjectDetails:: componentWillMount");
        // console.log(this.props);
        let params = this.props.match.params;
        let object_id = params.objectId;


        //console.log(this.props.currentObject);

        if (this.props.currentObject == null || this.props.currentObject.object.objectId !== object_id) {
            let p = null;
            if (this.props.list != null)
            {
                p = this.props.list.find((p) => p.objectId === object_id);
            }
            this.props.loadProject(object_id, (p != null) ? p.name : "");
        }
    }

    componentDidMount()
    {
        this.props.clearInterval();
        this.props.setInterval(this.saverTimeout, 500);
    }

    componentWillUnmount()
    {
        // make sure eveything is saving, before we get out of here.
        this.saveFields(this.props.changes);
        this.mounted = false;
    }

    saverTimeout()
    {
        //console.log("saverTimeout");

        var changedFields = this.props.changes.changedFields;
        let isNoChangedFields = details.isObjectEmpty(changedFields);

        // if there are changes and it has been 5 secons since our last change, then save it
        if (!isNoChangedFields)
        {
            if (this.last_update_time + 2000 <= Date.now())
            {
                this.saveFields();
            }
        }
    }

    saveFields()
    {
        this.props.saveFields(this.props.changes);
        this.last_update_time = 0;
    }

    updateField(name, value, saveImmediately)
    {
        //console.log("update Field "+name);
        this.last_update_time = Date.now();
        this.props.updateField(name, value);
    }


    render() {
        const {t} = this.props;

        // console.log("ProjectDetails:: ",this.props);
        let params = this.props.match.params;
        let object_id = params.objectId;
        let object = this.props.currentObject;

        var title;
        var body;
        // var subtitle = "";

        if (this.props.isLoading) {

            title = t("Project");
            if (this.props.list != null)
            {
                let p = this.props.list.find((p) => p.objectId === object_id);
                if (p != null)
                {
                    title = p.name;
                }
            }

            body = <Spinner/>
        }
        else {

            let status = this.props.project_status;
            //Lock down region for certain impact status.
            let regionField = {field: "details.region_id", display: t("Region"), type: "Region", disabled:true};
            if (status != null && (status.ImpactRiskAssessment === "UNAVAILABLE" || status.ImpactRiskAssessment === "NEW")){
                regionField = {field: "details.region_id", display: t("Region"), type: "Region"};
            }

            let fields = [
                {field: "details.name", display: t("Project Name"), type: "text"},
                {field: "details.date", display: t("Project Date"), type: "text"},
                {field: "details.proponent_name", display: t("Proponent"), type: "text"},
                {field: "details.short_desc", display: t("Project Short Description"), type: "text"},
                {field: "details.desc", display: t("Project Long Description"), type: "ftext"},
                regionField,
                {field: "details.project_categories", display: t("Project Types"), type: "ProjectCategory"}
            ];

            if(this.props.detailsReadOnly){
                fields = [
                    {field: "details.name", display: t("Project Name"), type: "readonlytext"},
                    {field: "details.date", display: t("Project Date"), type: "readonlytext"},
                    {field: "details.proponent_name", display: t("Proponent"), type: "readonlytext"},
                    {field: "details.short_desc", display: t("Project Short Description"), type: "readonlytext"},
                    {field: "details.desc", display: t("Project Long Description"), type: "ftext"},
                    {field: "details.region_id", display: t("Region"), type: "Region"},
                    {field: "details.project_categories", display: t("Project Types"), type: "ProjectCategory"}
                ];
            }

            // if (this.props.changes.savedStatus === "unchanged") {
            //     subtitle = "";
            // }
            // else if (this.props.changes.savedStatus === "saved") {
            //     subtitle = t("All Changes Saved");
            // }
            // else if (this.props.changes.savedStatus === "saving") {
            //     subtitle = t("Saving") + "...";
            // }
            // else {
            //     subtitle = t("Saving") + "...";
            // }

            if (object == null || object.object == null) {
                title = t("Project not Found");
                body = <div>{title}</div>
            }
            else {
                // title = object.object.name;

                if(this.props.detailsReadOnly){
                    body = (
                        <div className="row">
                            <div className="panel panel-default">
                                <div className="panel-body p-lg text-left">
                                    <DataDetailsReadOnly
                                        updateField={this.updateField.bind(this)}
                                        saveFields={this.saveFields.bind(this)}
                                        currentObject={this.props.currentObject}
                                        fields={fields}/>
                                </div>
                            </div>
                        </div>
                    )

                }else {

                    body = (
                        <div className="row">
                            <div className="panel panel-default">
                                <div className="panel-body p-lg text-left">
                                    <DataDetails
                                        updateField={this.updateField.bind(this)}
                                        saveFields={this.saveFields.bind(this)}
                                        currentObject={this.props.currentObject}
                                        fields={fields}/>
                                </div>
                            </div>
                        </div>
                    )
                }

            }
        }
        return (
                <div>

                        {body}

                </div>
        )
    }
}



export default withTranslation()(ReactTimeout(ProjectDetails))
