/* tslint:disable */
/* eslint-disable */
/**
 * eBase REST api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ClientLibraryConfig,
  EbaseResult,
  EbaseResultDatasetPublishingInfoResult,
  EbaseResultEnvidObjectResult,
  EbaseResultListAlertResult,
  EbaseResultListClientLibraryResult,
  EbaseResultListEnvidObjectSmallResult,
  EbaseResultListProjectSmallResult,
  EbaseResultListReportTemplateResult,
  EbaseResultListUpdateFieldResult,
  EbaseResultListUserResult,
  EbaseResultListVersionDetailsSmallResult,
  EbaseResultMapProjectActionTypeProjectActionStatus,
  EbaseResultProjectObjectsResult,
  EbaseResultString,
  EbaseResultUserResult,
  NewMilestoneRequest,
  NewObjectRequest,
  NewProjectRoleRequest,
  ObjectRequest,
  ProjectObjectsRequest,
  PublishDatasetRequest,
  UpdateFieldRequest,
  UpdateFieldsRequest,
  UpdateUserRequest,
} from '../models/index';
import {
    ClientLibraryConfigFromJSON,
    ClientLibraryConfigToJSON,
    EbaseResultFromJSON,
    EbaseResultToJSON,
    EbaseResultDatasetPublishingInfoResultFromJSON,
    EbaseResultDatasetPublishingInfoResultToJSON,
    EbaseResultEnvidObjectResultFromJSON,
    EbaseResultEnvidObjectResultToJSON,
    EbaseResultListAlertResultFromJSON,
    EbaseResultListAlertResultToJSON,
    EbaseResultListClientLibraryResultFromJSON,
    EbaseResultListClientLibraryResultToJSON,
    EbaseResultListEnvidObjectSmallResultFromJSON,
    EbaseResultListEnvidObjectSmallResultToJSON,
    EbaseResultListProjectSmallResultFromJSON,
    EbaseResultListProjectSmallResultToJSON,
    EbaseResultListReportTemplateResultFromJSON,
    EbaseResultListReportTemplateResultToJSON,
    EbaseResultListUpdateFieldResultFromJSON,
    EbaseResultListUpdateFieldResultToJSON,
    EbaseResultListUserResultFromJSON,
    EbaseResultListUserResultToJSON,
    EbaseResultListVersionDetailsSmallResultFromJSON,
    EbaseResultListVersionDetailsSmallResultToJSON,
    EbaseResultMapProjectActionTypeProjectActionStatusFromJSON,
    EbaseResultMapProjectActionTypeProjectActionStatusToJSON,
    EbaseResultProjectObjectsResultFromJSON,
    EbaseResultProjectObjectsResultToJSON,
    EbaseResultStringFromJSON,
    EbaseResultStringToJSON,
    EbaseResultUserResultFromJSON,
    EbaseResultUserResultToJSON,
    NewMilestoneRequestFromJSON,
    NewMilestoneRequestToJSON,
    NewObjectRequestFromJSON,
    NewObjectRequestToJSON,
    NewProjectRoleRequestFromJSON,
    NewProjectRoleRequestToJSON,
    ObjectRequestFromJSON,
    ObjectRequestToJSON,
    ProjectObjectsRequestFromJSON,
    ProjectObjectsRequestToJSON,
    PublishDatasetRequestFromJSON,
    PublishDatasetRequestToJSON,
    UpdateFieldRequestFromJSON,
    UpdateFieldRequestToJSON,
    UpdateFieldsRequestFromJSON,
    UpdateFieldsRequestToJSON,
    UpdateUserRequestFromJSON,
    UpdateUserRequestToJSON,
} from '../models/index';

export interface DeleteTemplateRequest {
    templateId: string;
}

export interface GetChangedObjectsForSessionDatasetRequest {
    version?: string;
}

export interface GetDatasetsForUserRequest {
    objectRequest?: ObjectRequest;
}

export interface GetProjectRequest {
    objectRequest?: ObjectRequest;
}

export interface GetProjectObjectsRequest {
    projectObjectsRequest?: ProjectObjectsRequest;
}

export interface GetProjectStatusRequest {
    projectObjectsRequest?: ProjectObjectsRequest;
}

export interface GetUserInfoRequest {
    userId: string;
}

export interface NewProjectObjectRequest {
    newObjectRequest?: NewObjectRequest;
}

export interface PublishCurrentDatasetRequest {
    publishDatasetRequest?: PublishDatasetRequest;
}

export interface SaveProjectMilestoneRequest {
    newMilestoneRequest?: NewMilestoneRequest;
}

export interface SaveProjectRoleRequest {
    newProjectRoleRequest?: NewProjectRoleRequest;
}

export interface UpdateClientLibraryConfigRequest {
    clientId: string;
    datasetId: string;
    clientLibraryConfig: ClientLibraryConfig;
}

export interface UpdateFields1Request {
    updateFieldRequest?: UpdateFieldRequest;
}

export interface UpdateProjectFieldsRequest {
    updateFieldsRequest?: Array<UpdateFieldsRequest>;
}

export interface UpdateUserOperationRequest {
    updateUserRequest?: UpdateUserRequest;
}

/**
 * EbaseCoreWSApi - interface
 * 
 * @export
 * @interface EbaseCoreWSApiInterface
 */
export interface EbaseCoreWSApiInterface {
    /**
     * 
     * @param {string} templateId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EbaseCoreWSApiInterface
     */
    deleteTemplateRaw(requestParameters: DeleteTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EbaseResultListReportTemplateResult>>;

    /**
     */
    deleteTemplate(templateId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EbaseResultListReportTemplateResult>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EbaseCoreWSApiInterface
     */
    getAlertsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EbaseResultListAlertResult>>;

    /**
     */
    getAlerts(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EbaseResultListAlertResult>;

    /**
     * 
     * @param {string} [version] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EbaseCoreWSApiInterface
     */
    getChangedObjectsForSessionDatasetRaw(requestParameters: GetChangedObjectsForSessionDatasetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EbaseResultListEnvidObjectSmallResult>>;

    /**
     */
    getChangedObjectsForSessionDataset(version?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EbaseResultListEnvidObjectSmallResult>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EbaseCoreWSApiInterface
     */
    getClientRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>>;

    /**
     */
    getClient(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EbaseCoreWSApiInterface
     */
    getClientsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EbaseResult>>;

    /**
     */
    getClients(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EbaseResult>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EbaseCoreWSApiInterface
     */
    getDatasetUsersRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EbaseResultListUserResult>>;

    /**
     */
    getDatasetUsers(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EbaseResultListUserResult>;

    /**
     * 
     * @param {ObjectRequest} [objectRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EbaseCoreWSApiInterface
     */
    getDatasetsForUserRaw(requestParameters: GetDatasetsForUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EbaseResultListClientLibraryResult>>;

    /**
     */
    getDatasetsForUser(objectRequest?: ObjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EbaseResultListClientLibraryResult>;

    /**
     * 
     * @param {ObjectRequest} [objectRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EbaseCoreWSApiInterface
     */
    getProjectRaw(requestParameters: GetProjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EbaseResultEnvidObjectResult>>;

    /**
     */
    getProject(objectRequest?: ObjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EbaseResultEnvidObjectResult>;

    /**
     * 
     * @param {ProjectObjectsRequest} [projectObjectsRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EbaseCoreWSApiInterface
     */
    getProjectObjectsRaw(requestParameters: GetProjectObjectsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EbaseResultProjectObjectsResult>>;

    /**
     */
    getProjectObjects(projectObjectsRequest?: ProjectObjectsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EbaseResultProjectObjectsResult>;

    /**
     * 
     * @param {ProjectObjectsRequest} [projectObjectsRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EbaseCoreWSApiInterface
     */
    getProjectStatusRaw(requestParameters: GetProjectStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EbaseResultMapProjectActionTypeProjectActionStatus>>;

    /**
     */
    getProjectStatus(projectObjectsRequest?: ProjectObjectsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EbaseResultMapProjectActionTypeProjectActionStatus>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EbaseCoreWSApiInterface
     */
    getProjectsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EbaseResultListProjectSmallResult>>;

    /**
     */
    getProjects(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EbaseResultListProjectSmallResult>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EbaseCoreWSApiInterface
     */
    getSessionDatasetPublishingRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EbaseResultDatasetPublishingInfoResult>>;

    /**
     */
    getSessionDatasetPublishing(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EbaseResultDatasetPublishingInfoResult>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EbaseCoreWSApiInterface
     */
    getSessionDatasetVersionHistoryRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EbaseResultListVersionDetailsSmallResult>>;

    /**
     */
    getSessionDatasetVersionHistory(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EbaseResultListVersionDetailsSmallResult>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EbaseCoreWSApiInterface
     */
    getTemplatesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EbaseResultListReportTemplateResult>>;

    /**
     */
    getTemplates(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EbaseResultListReportTemplateResult>;

    /**
     * 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EbaseCoreWSApiInterface
     */
    getUserInfoRaw(requestParameters: GetUserInfoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EbaseResultUserResult>>;

    /**
     */
    getUserInfo(userId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EbaseResultUserResult>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EbaseCoreWSApiInterface
     */
    getUsersRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EbaseResultListUserResult>>;

    /**
     */
    getUsers(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EbaseResultListUserResult>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EbaseCoreWSApiInterface
     */
    newProjectRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EbaseResultEnvidObjectResult>>;

    /**
     */
    newProject(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EbaseResultEnvidObjectResult>;

    /**
     * 
     * @param {NewObjectRequest} [newObjectRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EbaseCoreWSApiInterface
     */
    newProjectObjectRaw(requestParameters: NewProjectObjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EbaseResultEnvidObjectResult>>;

    /**
     */
    newProjectObject(newObjectRequest?: NewObjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EbaseResultEnvidObjectResult>;

    /**
     * 
     * @param {PublishDatasetRequest} [publishDatasetRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EbaseCoreWSApiInterface
     */
    publishCurrentDatasetRaw(requestParameters: PublishCurrentDatasetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EbaseResultListEnvidObjectSmallResult>>;

    /**
     */
    publishCurrentDataset(publishDatasetRequest?: PublishDatasetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EbaseResultListEnvidObjectSmallResult>;

    /**
     * 
     * @param {NewMilestoneRequest} [newMilestoneRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EbaseCoreWSApiInterface
     */
    saveProjectMilestoneRaw(requestParameters: SaveProjectMilestoneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EbaseResultEnvidObjectResult>>;

    /**
     */
    saveProjectMilestone(newMilestoneRequest?: NewMilestoneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EbaseResultEnvidObjectResult>;

    /**
     * 
     * @param {NewProjectRoleRequest} [newProjectRoleRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EbaseCoreWSApiInterface
     */
    saveProjectRoleRaw(requestParameters: SaveProjectRoleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EbaseResultEnvidObjectResult>>;

    /**
     */
    saveProjectRole(newProjectRoleRequest?: NewProjectRoleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EbaseResultEnvidObjectResult>;

    /**
     * 
     * @param {string} clientId 
     * @param {string} datasetId 
     * @param {ClientLibraryConfig} clientLibraryConfig 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EbaseCoreWSApiInterface
     */
    updateClientLibraryConfigRaw(requestParameters: UpdateClientLibraryConfigRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EbaseResult>>;

    /**
     */
    updateClientLibraryConfig(clientId: string, datasetId: string, clientLibraryConfig: ClientLibraryConfig, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EbaseResult>;

    /**
     * 
     * @param {UpdateFieldRequest} [updateFieldRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EbaseCoreWSApiInterface
     */
    updateFields1Raw(requestParameters: UpdateFields1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EbaseResultListUpdateFieldResult>>;

    /**
     */
    updateFields1(updateFieldRequest?: UpdateFieldRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EbaseResultListUpdateFieldResult>;

    /**
     * 
     * @param {Array<UpdateFieldsRequest>} [updateFieldsRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EbaseCoreWSApiInterface
     */
    updateProjectFieldsRaw(requestParameters: UpdateProjectFieldsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EbaseResultString>>;

    /**
     */
    updateProjectFields(updateFieldsRequest?: Array<UpdateFieldsRequest>, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EbaseResultString>;

    /**
     * 
     * @param {UpdateUserRequest} [updateUserRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EbaseCoreWSApiInterface
     */
    updateUserRaw(requestParameters: UpdateUserOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EbaseResultUserResult>>;

    /**
     */
    updateUser(updateUserRequest?: UpdateUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EbaseResultUserResult>;

}

/**
 * 
 */
export class EbaseCoreWSApi extends runtime.BaseAPI implements EbaseCoreWSApiInterface {

    /**
     */
    async deleteTemplateRaw(requestParameters: DeleteTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EbaseResultListReportTemplateResult>> {
        if (requestParameters['templateId'] == null) {
            throw new runtime.RequiredError(
                'templateId',
                'Required parameter "templateId" was null or undefined when calling deleteTemplate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/core/write-system-report-template/{template_id}`.replace(`{${"template_id"}}`, encodeURIComponent(String(requestParameters['templateId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EbaseResultListReportTemplateResultFromJSON(jsonValue));
    }

    /**
     */
    async deleteTemplate(templateId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EbaseResultListReportTemplateResult> {
        const response = await this.deleteTemplateRaw({ templateId: templateId }, initOverrides);
        return await response.value();
    }

    /**
     */
    async getAlertsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EbaseResultListAlertResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/core/read-client-alerts`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EbaseResultListAlertResultFromJSON(jsonValue));
    }

    /**
     */
    async getAlerts(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EbaseResultListAlertResult> {
        const response = await this.getAlertsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getChangedObjectsForSessionDatasetRaw(requestParameters: GetChangedObjectsForSessionDatasetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EbaseResultListEnvidObjectSmallResult>> {
        const queryParameters: any = {};

        if (requestParameters['version'] != null) {
            queryParameters['version'] = requestParameters['version'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/core/read-client-datasetchanges`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EbaseResultListEnvidObjectSmallResultFromJSON(jsonValue));
    }

    /**
     */
    async getChangedObjectsForSessionDataset(version?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EbaseResultListEnvidObjectSmallResult> {
        const response = await this.getChangedObjectsForSessionDatasetRaw({ version: version }, initOverrides);
        return await response.value();
    }

    /**
     */
    async getClientRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/core/client`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async getClient(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.getClientRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getClientsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EbaseResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/core/read-client-list`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EbaseResultFromJSON(jsonValue));
    }

    /**
     */
    async getClients(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EbaseResult> {
        const response = await this.getClientsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getDatasetUsersRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EbaseResultListUserResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/core/read-user-datasetlist`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EbaseResultListUserResultFromJSON(jsonValue));
    }

    /**
     */
    async getDatasetUsers(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EbaseResultListUserResult> {
        const response = await this.getDatasetUsersRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getDatasetsForUserRaw(requestParameters: GetDatasetsForUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EbaseResultListClientLibraryResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/core/read-user-userdatasets`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ObjectRequestToJSON(requestParameters['objectRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EbaseResultListClientLibraryResultFromJSON(jsonValue));
    }

    /**
     */
    async getDatasetsForUser(objectRequest?: ObjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EbaseResultListClientLibraryResult> {
        const response = await this.getDatasetsForUserRaw({ objectRequest: objectRequest }, initOverrides);
        return await response.value();
    }

    /**
     */
    async getProjectRaw(requestParameters: GetProjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EbaseResultEnvidObjectResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/core/read-project-load`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ObjectRequestToJSON(requestParameters['objectRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EbaseResultEnvidObjectResultFromJSON(jsonValue));
    }

    /**
     */
    async getProject(objectRequest?: ObjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EbaseResultEnvidObjectResult> {
        const response = await this.getProjectRaw({ objectRequest: objectRequest }, initOverrides);
        return await response.value();
    }

    /**
     */
    async getProjectObjectsRaw(requestParameters: GetProjectObjectsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EbaseResultProjectObjectsResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/core/read-project-objects`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ProjectObjectsRequestToJSON(requestParameters['projectObjectsRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EbaseResultProjectObjectsResultFromJSON(jsonValue));
    }

    /**
     */
    async getProjectObjects(projectObjectsRequest?: ProjectObjectsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EbaseResultProjectObjectsResult> {
        const response = await this.getProjectObjectsRaw({ projectObjectsRequest: projectObjectsRequest }, initOverrides);
        return await response.value();
    }

    /**
     */
    async getProjectStatusRaw(requestParameters: GetProjectStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EbaseResultMapProjectActionTypeProjectActionStatus>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/core/read-project-status`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ProjectObjectsRequestToJSON(requestParameters['projectObjectsRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EbaseResultMapProjectActionTypeProjectActionStatusFromJSON(jsonValue));
    }

    /**
     */
    async getProjectStatus(projectObjectsRequest?: ProjectObjectsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EbaseResultMapProjectActionTypeProjectActionStatus> {
        const response = await this.getProjectStatusRaw({ projectObjectsRequest: projectObjectsRequest }, initOverrides);
        return await response.value();
    }

    /**
     */
    async getProjectsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EbaseResultListProjectSmallResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/core/read-project-list`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EbaseResultListProjectSmallResultFromJSON(jsonValue));
    }

    /**
     */
    async getProjects(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EbaseResultListProjectSmallResult> {
        const response = await this.getProjectsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getSessionDatasetPublishingRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EbaseResultDatasetPublishingInfoResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/core/read-client-datasetpublishing`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EbaseResultDatasetPublishingInfoResultFromJSON(jsonValue));
    }

    /**
     */
    async getSessionDatasetPublishing(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EbaseResultDatasetPublishingInfoResult> {
        const response = await this.getSessionDatasetPublishingRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getSessionDatasetVersionHistoryRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EbaseResultListVersionDetailsSmallResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/core/read-client-datasetversionhistory`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EbaseResultListVersionDetailsSmallResultFromJSON(jsonValue));
    }

    /**
     */
    async getSessionDatasetVersionHistory(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EbaseResultListVersionDetailsSmallResult> {
        const response = await this.getSessionDatasetVersionHistoryRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getTemplatesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EbaseResultListReportTemplateResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/core/read-system-report-templates`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EbaseResultListReportTemplateResultFromJSON(jsonValue));
    }

    /**
     */
    async getTemplates(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EbaseResultListReportTemplateResult> {
        const response = await this.getTemplatesRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getUserInfoRaw(requestParameters: GetUserInfoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EbaseResultUserResult>> {
        if (requestParameters['userId'] == null) {
            throw new runtime.RequiredError(
                'userId',
                'Required parameter "userId" was null or undefined when calling getUserInfo().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/core/read-user-info/{user_id}`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters['userId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EbaseResultUserResultFromJSON(jsonValue));
    }

    /**
     */
    async getUserInfo(userId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EbaseResultUserResult> {
        const response = await this.getUserInfoRaw({ userId: userId }, initOverrides);
        return await response.value();
    }

    /**
     */
    async getUsersRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EbaseResultListUserResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/core/read-user-list`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EbaseResultListUserResultFromJSON(jsonValue));
    }

    /**
     */
    async getUsers(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EbaseResultListUserResult> {
        const response = await this.getUsersRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async newProjectRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EbaseResultEnvidObjectResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/core/write-project-new`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EbaseResultEnvidObjectResultFromJSON(jsonValue));
    }

    /**
     */
    async newProject(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EbaseResultEnvidObjectResult> {
        const response = await this.newProjectRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async newProjectObjectRaw(requestParameters: NewProjectObjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EbaseResultEnvidObjectResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/core/write-project-newobject`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NewObjectRequestToJSON(requestParameters['newObjectRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EbaseResultEnvidObjectResultFromJSON(jsonValue));
    }

    /**
     */
    async newProjectObject(newObjectRequest?: NewObjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EbaseResultEnvidObjectResult> {
        const response = await this.newProjectObjectRaw({ newObjectRequest: newObjectRequest }, initOverrides);
        return await response.value();
    }

    /**
     */
    async publishCurrentDatasetRaw(requestParameters: PublishCurrentDatasetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EbaseResultListEnvidObjectSmallResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/core/write-client-publishdataset`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PublishDatasetRequestToJSON(requestParameters['publishDatasetRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EbaseResultListEnvidObjectSmallResultFromJSON(jsonValue));
    }

    /**
     */
    async publishCurrentDataset(publishDatasetRequest?: PublishDatasetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EbaseResultListEnvidObjectSmallResult> {
        const response = await this.publishCurrentDatasetRaw({ publishDatasetRequest: publishDatasetRequest }, initOverrides);
        return await response.value();
    }

    /**
     */
    async saveProjectMilestoneRaw(requestParameters: SaveProjectMilestoneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EbaseResultEnvidObjectResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/core/write-project-savemilestone`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NewMilestoneRequestToJSON(requestParameters['newMilestoneRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EbaseResultEnvidObjectResultFromJSON(jsonValue));
    }

    /**
     */
    async saveProjectMilestone(newMilestoneRequest?: NewMilestoneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EbaseResultEnvidObjectResult> {
        const response = await this.saveProjectMilestoneRaw({ newMilestoneRequest: newMilestoneRequest }, initOverrides);
        return await response.value();
    }

    /**
     */
    async saveProjectRoleRaw(requestParameters: SaveProjectRoleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EbaseResultEnvidObjectResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/core/write-project-saverole`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NewProjectRoleRequestToJSON(requestParameters['newProjectRoleRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EbaseResultEnvidObjectResultFromJSON(jsonValue));
    }

    /**
     */
    async saveProjectRole(newProjectRoleRequest?: NewProjectRoleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EbaseResultEnvidObjectResult> {
        const response = await this.saveProjectRoleRaw({ newProjectRoleRequest: newProjectRoleRequest }, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateClientLibraryConfigRaw(requestParameters: UpdateClientLibraryConfigRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EbaseResult>> {
        if (requestParameters['clientId'] == null) {
            throw new runtime.RequiredError(
                'clientId',
                'Required parameter "clientId" was null or undefined when calling updateClientLibraryConfig().'
            );
        }

        if (requestParameters['datasetId'] == null) {
            throw new runtime.RequiredError(
                'datasetId',
                'Required parameter "datasetId" was null or undefined when calling updateClientLibraryConfig().'
            );
        }

        if (requestParameters['clientLibraryConfig'] == null) {
            throw new runtime.RequiredError(
                'clientLibraryConfig',
                'Required parameter "clientLibraryConfig" was null or undefined when calling updateClientLibraryConfig().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/core/write-system-update-client/{client_id}/dataset/{dataset_id}/config`.replace(`{${"client_id"}}`, encodeURIComponent(String(requestParameters['clientId']))).replace(`{${"dataset_id"}}`, encodeURIComponent(String(requestParameters['datasetId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ClientLibraryConfigToJSON(requestParameters['clientLibraryConfig']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EbaseResultFromJSON(jsonValue));
    }

    /**
     */
    async updateClientLibraryConfig(clientId: string, datasetId: string, clientLibraryConfig: ClientLibraryConfig, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EbaseResult> {
        const response = await this.updateClientLibraryConfigRaw({ clientId: clientId, datasetId: datasetId, clientLibraryConfig: clientLibraryConfig }, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateFields1Raw(requestParameters: UpdateFields1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EbaseResultListUpdateFieldResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/core/write-project-updatefield`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateFieldRequestToJSON(requestParameters['updateFieldRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EbaseResultListUpdateFieldResultFromJSON(jsonValue));
    }

    /**
     */
    async updateFields1(updateFieldRequest?: UpdateFieldRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EbaseResultListUpdateFieldResult> {
        const response = await this.updateFields1Raw({ updateFieldRequest: updateFieldRequest }, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateProjectFieldsRaw(requestParameters: UpdateProjectFieldsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EbaseResultString>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/core/write-project-updatefields`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['updateFieldsRequest']!.map(UpdateFieldsRequestToJSON),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EbaseResultStringFromJSON(jsonValue));
    }

    /**
     */
    async updateProjectFields(updateFieldsRequest?: Array<UpdateFieldsRequest>, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EbaseResultString> {
        const response = await this.updateProjectFieldsRaw({ updateFieldsRequest: updateFieldsRequest }, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateUserRaw(requestParameters: UpdateUserOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EbaseResultUserResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/core/write-user-updateuser`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateUserRequestToJSON(requestParameters['updateUserRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EbaseResultUserResultFromJSON(jsonValue));
    }

    /**
     */
    async updateUser(updateUserRequest?: UpdateUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EbaseResultUserResult> {
        const response = await this.updateUserRaw({ updateUserRequest: updateUserRequest }, initOverrides);
        return await response.value();
    }

}
