/* tslint:disable */
/* eslint-disable */
/**
 * eBase REST api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { UserEvent } from './UserEvent';
import {
    UserEventFromJSON,
    UserEventFromJSONTyped,
    UserEventToJSON,
} from './UserEvent';

/**
 * 
 * @export
 * @interface AuditEventPublic
 */
export interface AuditEventPublic {
    /**
     * 
     * @type {string}
     * @memberof AuditEventPublic
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof AuditEventPublic
     */
    datasetId?: string;
    /**
     * 
     * @type {string}
     * @memberof AuditEventPublic
     */
    sharedId?: string;
    /**
     * 
     * @type {string}
     * @memberof AuditEventPublic
     */
    instanceId?: string;
    /**
     * 
     * @type {number}
     * @memberof AuditEventPublic
     */
    internalVersion?: number;
    /**
     * 
     * @type {string}
     * @memberof AuditEventPublic
     */
    eventType?: AuditEventPublicEventTypeEnum;
    /**
     * 
     * @type {UserEvent}
     * @memberof AuditEventPublic
     */
    userEvent?: UserEvent;
    /**
     * 
     * @type {string}
     * @memberof AuditEventPublic
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof AuditEventPublic
     */
    version?: string;
    /**
     * 
     * @type {string}
     * @memberof AuditEventPublic
     */
    value?: string;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof AuditEventPublic
     */
    payload?: { [key: string]: object; };
}


/**
 * @export
 */
export const AuditEventPublicEventTypeEnum = {
    Create: 'CREATE',
    Update: 'UPDATE',
    Link: 'LINK',
    Unlink: 'UNLINK',
    Delete: 'DELETE',
    Checkout: 'CHECKOUT',
    Checkin: 'CHECKIN',
    Publish: 'PUBLISH',
    System: 'SYSTEM',
    Error: 'ERROR'
} as const;
export type AuditEventPublicEventTypeEnum = typeof AuditEventPublicEventTypeEnum[keyof typeof AuditEventPublicEventTypeEnum];


/**
 * Check if a given object implements the AuditEventPublic interface.
 */
export function instanceOfAuditEventPublic(value: object): value is AuditEventPublic {
    return true;
}

export function AuditEventPublicFromJSON(json: any): AuditEventPublic {
    return AuditEventPublicFromJSONTyped(json, false);
}

export function AuditEventPublicFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuditEventPublic {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'datasetId': json['datasetId'] == null ? undefined : json['datasetId'],
        'sharedId': json['sharedId'] == null ? undefined : json['sharedId'],
        'instanceId': json['instanceId'] == null ? undefined : json['instanceId'],
        'internalVersion': json['internalVersion'] == null ? undefined : json['internalVersion'],
        'eventType': json['eventType'] == null ? undefined : json['eventType'],
        'userEvent': json['userEvent'] == null ? undefined : UserEventFromJSON(json['userEvent']),
        'description': json['description'] == null ? undefined : json['description'],
        'version': json['version'] == null ? undefined : json['version'],
        'value': json['value'] == null ? undefined : json['value'],
        'payload': json['payload'] == null ? undefined : json['payload'],
    };
}

export function AuditEventPublicToJSON(value?: AuditEventPublic | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'datasetId': value['datasetId'],
        'sharedId': value['sharedId'],
        'instanceId': value['instanceId'],
        'internalVersion': value['internalVersion'],
        'eventType': value['eventType'],
        'userEvent': UserEventToJSON(value['userEvent']),
        'description': value['description'],
        'version': value['version'],
        'value': value['value'],
        'payload': value['payload'],
    };
}

